import { getAsync } from "./http-client";
import { ParamsType } from "../@types/paramsType";

const url = "/province";
const provinceApi = {
  get: (params: ParamsType) => {
    return getAsync(`${url}`, params);
  },
};

export default provinceApi;
