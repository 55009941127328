import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import BannerComponent from "../../components/banner-component/BannerComponent";
import { useTranslation } from "react-i18next";
import AboutUs from "../../components/views/about-page/aboutus/AboutUs";
import OurTeam from "../../components/views/about-page/ourteam/OurTeam";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import { dispatch, useSelector } from "../../redux/store";
import { IAboutPage } from "../../@types/aboutPage";
import { useEffect } from "react";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";


interface Props extends RouteChildrenProps, LayoutProps { }

export default function AboutUsPage(props: Props) {
  const { t } = useTranslation();
  const { about } = useSelector((state) => state.webcontent);
  let dataAbout: IAboutPage | null = null;
  if (about && about.length > 0) {
    dataAbout = JSON.parse(about[0].content);
  }
  useEffect(() => {
    if (!about) {
      dispatch(getWebcontentPage(WebcontentType.About));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <h1 className="aboutPage">

      <BannerComponent
        title={dataAbout?.banner.title ? dataAbout?.banner.title : t("aboutPage.banner.title")}
        subtitle={dataAbout?.banner.content ? dataAbout?.banner.content : t("aboutPage.banner.subtitle")}
        bannerUrl={dataAbout?.banner.backgroundUrl}
      />
      <BreadcrumbHeader />
      <AboutUs
        backgroundUrl={dataAbout?.aboutUs.backgroundUrl ? dataAbout.aboutUs.backgroundUrl : ""}
        content={dataAbout?.aboutUs.content ? dataAbout?.aboutUs.content : ""}
        title={dataAbout?.aboutUs.title ? dataAbout?.aboutUs.title : ""}
      />
      <OurTeam
        content={dataAbout?.ourTeam.content ? dataAbout?.ourTeam.content : ""}
        title={dataAbout?.ourTeam.title ? dataAbout?.ourTeam.title : ""}
        listImageUrl={dataAbout?.ourTeam.listImageUrl ? dataAbout?.ourTeam.listImageUrl : []}
      />
    </h1>
  );
}

