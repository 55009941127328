import { Col, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTE_PATHS } from "../../../../constants/url-config";
import { useSelector } from "../../../../redux/store";
import ButtonCustom from "../../../button/ButtonCustom";
import { ModalApplyCareer } from "../../careers-page";
import "./HeadlineComponent.scss";

type Props = {};

const HeadlineComponent = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { careerHighlight } = useSelector((state) => state.career);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSearch = () => {
    history.push(ROUTE_PATHS.Careers);
  };
  return (
    <>
      <Row gutter={[0, { xs: 24, xl: 0 }]} className="headline">
        <Col
          xs={24}
          xl={12}
          className="headline__content"
          data-aos="zoom-in"
          data-aos-duration="700"
        >
          <div className=" headline--title">{t("homepage.headline.title")}</div>
          <div className="title__content">{t("homepage.headline.desc")}</div>
        </Col>
        <Col xs={24} xl={12} data-aos="zoom-in" data-aos-duration="700">
          <div className="button">
            <ButtonCustom
              style={{ marginRight: 10 }}
              className="whiteButton button__custom"
              title={t('searchJob')}
              onClick={handleSearch}
            />
            <ButtonCustom
              className="button__custom"
              title={t("homepage.headline.applyJob")}
              onClick={showModal}
            />
          </div>
        </Col>
      </Row>
      {careerHighlight && careerHighlight.length > 0 ? (
  <ModalApplyCareer
    careerCurrent={careerHighlight[0].id}
    isModalOpen={isModalOpen}
    handleClose={handleClose}
  />
) : null}
    </>
  );
};

export default HeadlineComponent;
