import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplyJob } from "../../@types/applyJob";
import applyJobApi from "../../services/apply-job/applyjob.service";
import { openNotification } from "../../utils/notification";

export const postApplyJob = createAsyncThunk(
  "applyCareer/post",
  async (data: ApplyJob) => {
    await applyJobApi.post(data);
    await openNotification('success','Ứng tuyển thành công');
  }
);

const initialState = {};

const slice = createSlice({
  name: "apply",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default slice.reducer;
