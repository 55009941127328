import React, { useEffect, useState } from "react";
import "./News.scss";
import { Col, Row } from "antd";
import CardComponent from "../../../card-component/CardComponent";
import ButtonCustom from "../../../button/ButtonCustom";
import { dispatch, useSelector } from "../../../../redux/store";
import { ROUTE_PATHS } from "../../../../constants/url-config";
import { NEWS } from "../../../../constants/app-constants";
import { getNewsEvent, getServiceEvent } from "../../../../redux/slices/event";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  content: string
};

const News = ({
  title,
  content
}: Props) => {
  const { news } = useSelector((state) => state.event);
  const [showAllNews, setShowAllNews] = useState(false);
  useEffect(() => {
    dispatch(
      getNewsEvent({
        pageIndex: 1,
        pageSize: 100,
        eventType: NEWS,
      })
    );
  }, []);

  const { t } = useTranslation();
  return (
    <div className="news containerCustom">
      <div data-aos="fade-down" data-aos-duration="700" className="title">
        {title? title:t("homepage.news.title")}
      </div>
      <div
        data-aos="fade-down"
        data-aos-duration="700"
        className="title__content"
      >
        {content?content:t("homepage.news.desc")}
      </div>
      <Row gutter={[{ xl: 20 }, { xl: 0, xs: 30 }]} className="news__content">
        {showAllNews
          ? news.map((eventNews) => (
            <Col key={eventNews.id} xs={24} xl={8} data-aos="fade-up"
              data-aos-duration="700">
              <CardComponent

                title={eventNews.name}
                content={eventNews.description}
                picture={eventNews.thumbnail}
                id={eventNews.id}
                href={ROUTE_PATHS.NewsDetail.replace(
                  ":id",
                  eventNews.id.toString()
                )}
              />
            </Col>
          ))
          : news.slice(0, 3).map((eventNews) => (
            <Col key={eventNews.id} xs={24} xl={8} data-aos="fade-up"
              data-aos-duration="700">
              <CardComponent
                title={eventNews.name}
                content={eventNews.description}
                picture={eventNews.thumbnail}
                id={eventNews.id}
                href={ROUTE_PATHS.NewsDetail.replace(
                  ":id",
                  eventNews.id.toString()
                )}
              />
            </Col>
          ))}
      </Row>
      <div className="news__button">
        {!showAllNews && (<ButtonCustom
          title={t("homepage.news.button")}
          className="mainButton"
          onClick={() => setShowAllNews(true)}
        />)}

      </div>
    </div>
  );
};

export default News;
