const API_URL = process.env.REACT_APP_API_ENDPOINT

enum ROUTE_PATHS {
	"Home" = "/",
	"SignIn" = "/sign-in",
	"SignUp" = "/sign-up",
	"ForgotPassword" = "forgot-password",
	"Profile" = "/profile",
	"Payroll" = "/payroll",
	"Search" ="/search",
	"Careers" = "/careers",
	"Contact" = "/contact",
	TaxDocument = "/tax-document",
	Invoice = "/invoice",
	"About" = "/about",
	"Services" = "/services",
	"CareersDetail" = `/careers/careers-detail/:id`,
	"NewsDetail" = `/news/news-details/:id`,
	"ServiceDetail" = `/services/services-detail/:id`,
}

//this variable is using for params url
const ROUTE_DYNAMIC_VARIABLE_LIST = [":id"]

//this variable is using for replace params url with real data
enum ROUTE_DYNAMIC_VARIABLE {
	"id" = ":id",
}
export default API_URL

export { ROUTE_PATHS, ROUTE_DYNAMIC_VARIABLE_LIST, ROUTE_DYNAMIC_VARIABLE }
