import React, { useEffect } from "react";
import "./ServicePage.scss";
import BannerComponent from "../../components/banner-component/BannerComponent";
import { useTranslation } from "react-i18next";
import OurServices from "../../components/views/services-page/our-services/OurServices";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import { dispatch, useSelector } from "../../redux/store";
import { IServicePage } from "../../@types/servicePage";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";

type Props = {};

const ServicePage = (props: Props) => {
  const { t } = useTranslation();
  const { service } = useSelector((state) => state.webcontent);
  let dataService: IServicePage | null = null;
  if (service && service.length > 0) {
    dataService = JSON.parse(service[0].content);
  }
  useEffect(() => {
    if (!service) {
      dispatch(getWebcontentPage(WebcontentType.Service));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BannerComponent
        title={dataService?.banner.title ?dataService?.banner.title :t("servicesPage.banner.title")}
        subtitle={dataService?.banner.content?dataService?.banner.content:t("servicesPage.banner.subtitle")}
        bannerUrl={dataService?.banner.backgroundUrl?dataService?.banner.backgroundUrl:""}
      />
      <BreadcrumbHeader />
      <section>
        <OurServices />
      </section>
    </>
  );
};

export default ServicePage;
