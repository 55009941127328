import React from "react";
import "./anonymous.scss";
import Footer from "../footer";
import MainHeader from "../main/main-header";

const AnonymousLayout = ({ children }: any) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return (
    <>
     <MainHeader/>
      {children}
      <Footer />
    </>
  );

};

export default AnonymousLayout;
