
import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import { Col, Row } from "antd";
import { Typography } from "antd";
import './Contacts.scss';
import { useTranslation } from "react-i18next";
import BannerComponent from "../../components/banner-component/BannerComponent";
import Feedback from "../../components/views/contact/FeedbackForm";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import useResponsive from "../../hooks/useResponsive";
import { dispatch, useSelector } from "../../redux/store";
import { IContactPage } from "../../@types/contactPage";
import { useEffect } from "react";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";

const { Title } = Typography;
interface Props extends RouteChildrenProps, LayoutProps { }

export default function ContactUsPage(props: Props) {
  const { t } = useTranslation();
  const { contact } = useSelector((state) => state.webcontent);
  let dataContact: IContactPage | null = null;
  if (contact && contact.length > 0) {
    dataContact = JSON.parse(contact[0].content);
  }
  useEffect(() => {
    if (!contact) {
      dispatch(getWebcontentPage(WebcontentType.Contact));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <BannerComponent
        title={dataContact?.banner.title ? dataContact?.banner.title : t("contactPage.banner.title")}
        subtitle={dataContact?.banner.content ? dataContact?.banner.content : t("contactPage.banner.subtitle")}
        bannerUrl={dataContact?.banner.backgroundUrl ? dataContact?.banner.backgroundUrl : ""}
      />
      <BreadcrumbHeader />
      <div className="containerCustom">
        <Row gutter={{ xs: 0, sm: 20, md: 20, lg: 40 }} className=" contactPage">
          <Col span={12} data-aos="fade-up"
            data-aos-anchor-placement="top-bottom" data-aos-duration="700">
            <Feedback />
          </Col>
          <Col span={12} data-aos="fade-down"
            data-aos-anchor-placement="top-bottom" data-aos-duration="700">
            <div className="contactPage__right">
              <Title level={5}>
                {dataContact?.feedback ? dataContact?.feedback : t("contactPage.banner.content")}
              </Title>
            </div>
          </Col>
        </Row>
      </div>

    </div>
  );
}
