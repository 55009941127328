import React, { useMemo } from "react";
import "./SearchToolbar.scss";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import noDataPic from "../../../../assets/images/search-page/noData.svg";
import { useTranslation } from "react-i18next";
import useResponsive from "../../../../hooks/useResponsive";
import { useHistory } from "react-router";

type Props = {};

type IListRouter = {
  [key: string]: string;
}
const LIST_ROUTER : IListRouter = {
  taxDocument: "/tax-document",
  invoice: "/invoice",
  payroll: "https://hrm.agari.com.vn",
}

const SearchToolbar = (props: Props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const defaultValues = useMemo(() => {
    return {
      filter: "none",
      search: "",
    };
  }, []);

  const onFinish = (values: {
    filter: string;
    search: string;
  }) => {
    const route = LIST_ROUTER[values.filter];
    if(route.includes("http")) {
      window.location.href = route;
      return;
    }
    history.push(route);
  };
  const { isMobile } = useResponsive();
  return (
    <>
      <Form
        form={form}
        name="searchForm"
        onFinish={onFinish}
        initialValues={defaultValues}
      >
        <Row gutter={[10, 0]} className="search__row"  data-aos="zoom-out-down">
          <Col xl={5} className="search__item" >
            <Form.Item name="filter">
              <Select
                suffixIcon={<CaretDownOutlined />}
                size="large"
                defaultValue="none"
                style={{ width: "100%" }}
                options={[
                  { value: "none", label: t("none") },
                  { value: "taxDocument", label: t("searchPage.taxSearch") },
                  // { value: "invoice", label: t("searchPage.billSearch") },
                  { value: "payroll", label: t("searchPage.payroll") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xl={15} className="search__item"> 
            <Form.Item name="search">
              <Input
                width={"100%"}
                size="large"
                // placeholder={t("Enter the code")}
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>

          <Col xl={4} className="search__col__button">
            <Button htmlType="submit" size="large" className="search__button">
              {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>
      {isMobile ? null : (
        <div className="noData" data-aos="zoom-out-down">
          <img alt="No Data" src={noDataPic} />
        </div>
      )}
    </>
  );
};

export default SearchToolbar;
