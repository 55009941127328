import { Button, Drawer, Dropdown, Menu, Space } from "antd";
import logoUrl from "../../../../../assets/images/logo.png";
import logoRespon from "../../../../../assets/images/logorespon.svg";

import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";
import useResponsive from "../../../../../hooks/useResponsive";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../constants/url-config";
import { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { dispatch, useSelector } from "../../../../../redux/store";
import { getServiceEvent } from "../../../../../redux/slices/event";
import { SERVICE } from "../../../../../constants/app-constants";
import { ILogo } from "../../../../../@types/logoPage";
import { getWebcontentPage } from "../../../../../redux/slices/webcontent";
import { WebcontentType } from "../../../../../@types/webcontent";
import { CookieConstants } from "../../../../../constants/local";
import i18n from "../../../../../utils/i18n";

type Props = {};

const HeaderLayout = (props: Props) => {

  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { isTablet } = useResponsive()
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const { services } = useSelector((state) => state.event)
  const { logo } = useSelector((state) => state.webcontent);
  // language
  const allLangs = [
    {
      key: `vi`,
      label: t("homeMenu.nav.select.1"),
    },
    {
      key: `en`,
      label: t("homeMenu.nav.select.0"),
    },
  ]

  const langStorage = localStorage.getItem(CookieConstants.LANGUAGE) || allLangs[0];
  const currentLang = allLangs.find((_lang) => _lang.key === langStorage) || allLangs[0];
  let dataLogo: ILogo | null = null;
  const [defaultLang, setDefaultLang] = useState<string>(currentLang.label);
  // 

  if (logo && logo.length > 0) {
    dataLogo = JSON.parse(logo[0].content);
  }
  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const changeLangueHandle = (lang: string) => {
    localStorage.setItem(CookieConstants.LANGUAGE, lang);
    i18n.changeLanguage(lang);
    setDefaultLang(lang);
  }

  useEffect(() => {
    dispatch(getServiceEvent({
      pageIndex: 1,
      pageSize: 100,
      eventType: SERVICE
    }))
  }, [])

  useEffect(() => {
    if (!logo) {
      dispatch(getWebcontentPage(WebcontentType.Logo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const items = [
    {
      label: <Link to={ROUTE_PATHS.Home}>{t("homeMenu.content.0")}</Link>,
      key: "home",
    },
    {
      label: <Link to={ROUTE_PATHS.About}>{t("homeMenu.content.1")}</Link>,
      key: "about",
    },
    {
      label: <Link to={ROUTE_PATHS.Services}>{t("homeMenu.content.2")}</Link>,
      key: "service",
      icon: isMobile ? null : <CaretDownOutlined />,

      children: services.map(item => ({
        label: <Link to={ROUTE_PATHS.ServiceDetail.replace(":id", item.id.toString())}>{item.name}</Link>,
        key: item.id
      }))
    },
    {
      label: <Link to={ROUTE_PATHS.Careers}>{t("homeMenu.content.9")}</Link>,
      key: "careers",
    },
    {
      label: <a href="https://hrm.agari.com.vn/">{t("homeMenu.content.10")}</a>,
      key: "payroll",
    },
    {
      label: <Link to={ROUTE_PATHS.Contact}>{t("homeMenu.content.11")}</Link>,
      key: "contact",
    },
  ];
  const newItem = {
    label: t("homeMenu.item.0"),
    key: "language",
    children: [
      { label: t("homeMenu.item.1"), key: "english" },
      { label: t("homeMenu.item.2"), key: "vietnam" },
    ],
  };
  const updatedItems = [...items, newItem];

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const menutop = (
    <Menu
      onClick={(e) => changeLangueHandle(e.key)}
      items={allLangs}
    />
  );

  return (
    <div className="header">
      {isMobile || isTablet ? null : (
        <Menu mode="horizontal" className="header__top containerCustom" >
          <Link to={ROUTE_PATHS.Search}>
            <Button className="header__top--button">
              {t("homeMenu.nav.search")}
            </Button>
          </Link>
          <Dropdown overlay={menutop} className="header__top__dropdown" >
            <Space>
              {t(`${defaultLang}`)}
              <CaretDownOutlined />
            </Space>
          </Dropdown>
        </Menu>
      )}

      <div className="header__nav containerCustom">
        <Link to={ROUTE_PATHS.Home}>
          <img src={dataLogo ? dataLogo.logoHeader : logoUrl} alt="" />
        </Link>

        {isMobile || isTablet ? (
          <>
            <Link to={ROUTE_PATHS.Search}>
              <Button className="header__top--button header__top--button--res">
                {t("homeMenu.nav.search")}
              </Button>
            </Link>
            <MenuOutlined
              key="menu-icon"
              onClick={showDrawer}
              style={{ color: "white" }}
            />
          </>
        ) : (
          <Menu

            mode="horizontal"
            disabledOverflow={true}
            itemIcon={true}
            inlineCollapsed={false}
            className="header__nav__menu"
            items={items}
            style={{ float: "right" }}
          ></Menu>
        )}
      </div>
      <Drawer
        title={<Link to={ROUTE_PATHS.Home}><img src={logoRespon} alt="Logo" /></Link>}
        placement="left"
        onClose={onClose}
        open={open}
        className="drawerNav"
        width={"86%"}
      >
        <Menu
          mode="inline"
          items={updatedItems}
          inlineIndent={16}
          itemIcon={false}
        ></Menu>
      </Drawer>
    </div>
  );
};

export default HeaderLayout;
