import { Typography } from "antd";
import ContentComponent from "../../components/content-component/ContentComponent";
import layout from "../../assets/images/carrers/layout.svg";
import "./CareersDetail.scss";
import { useTranslation } from "react-i18next";
import { dispatch, useSelector } from "../../redux/store";
import { useParams } from "react-router";
import { useEffect } from "react";
import { getOnecareer } from "../../redux/slices/career";
import ReactHtmlParser from "react-html-parser";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import moment from "moment";

const { Title, Text } = Typography;

type Props = {};

const CareersDetail = (props: Props) => {
  const { careerDetail } = useSelector((state) => state.career);
  const params = useParams<{ id: any }>();
  console.log("careerDetail", careerDetail);
  useEffect(() => {
    if (params) {
      dispatch(getOnecareer(params.id));
    }
  }, [params]);

  const { t } = useTranslation();
  return (
    <div className="careersDetail ">
      <BreadcrumbHeader />
      <div className="containerCustom contentDetail">
      <Title level={2} className='title serviceDetail__title' data-aos="fade-up" data-aos-duration="700">{careerDetail.name}</Title>
      <div className='serviceDetail__date' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">{moment(careerDetail.createdAt).format("DD-MM-YYYY")}</div>
      <div className='serviceDetail__date' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">{careerDetail.field}, {t(`${careerDetail.jobType}`)}</div>
      <div className='serviceDetail__desc' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">{careerDetail.description}</div>
      <Text className='serviceDetail__content' data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">{ReactHtmlParser(careerDetail.content)}</Text>
      </div>
      {/* <div className="containerCustom">
        {t("carrersDetail.desc1")}
        <br />
        {t("carrersDetail.desc2")}

      </div> */}
    </div>
  );
};

export default CareersDetail;
