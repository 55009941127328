import { CSSProperties } from "react";
import "./ButtonCustom.scss";
import { Button } from "antd";

type Props = {
  title: string;
  style?: CSSProperties;
  className?: string;
  onClick?:any;
};

const ButtonCustom = ({ title, style,className,onClick, ...other }: Props) => {
  return (
    <>
      <Button  {...other} style={style} onClick={onClick} type='default' className={`buttonCustom ${className}`}>
        {title}
      </Button>
    </>
  );
};

export default ButtonCustom;
