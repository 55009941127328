import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import productReducer from "./slices/product";
import brandReducer from "./slices/branding";
import provinceReducer from "./slices/province";
import districtReducer from "./slices/district";
import webcontentReducer from './slices/webcontent';

import eventReducer from "./slices/event";

import career from "./slices/career";
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  product: productReducer,
  event: eventReducer,
  career: career,
  brand: brandReducer,
  province: provinceReducer,
  district: districtReducer,
  webcontent: webcontentReducer,

});

export default rootReducer;
