import { Col, Row } from "antd";
import { IChooseHomePage } from "../../../../@types/homePage";
import picture from "../../../../assets/images/home-page/Rectangle 91.png";
import './ChooseUs.scss';

const ChooseUS = ({
  title,
  content,
  image,
  subtitle,
}: IChooseHomePage) => {
  return (
    <div className="containerCustom">
      <Row className="chooseUs" gutter={[{ xs: 0, xl: 80 }, { xs: 24, xl: 0 }]}>
        <Col xs={24} xl={12}>
          <div data-aos="fade-up"
            data-aos-duration="700" className="chooseUs__image">
            <img src={image ? image : picture} alt="Why Choose Us" />
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div data-aos="fade-down"
            data-aos-duration="700" className="chooseUs__content">
            <div className="chooseUs__content--title">{title ? title : 'Why choose us?'}</div>
            <div className="title__content">
              {subtitle ? subtitle : `Best strong network in Vietnam (various database sources, local
              insightful)Experienced and quick action in Outsourcing
              managementStrong finance & highly committed (on time payment)
              –highly prestigious on Outsourcing management`}
            </div>
            <Row gutter={[{ xs: 0, xl: 140 }, { xs: 24, xl: 32 }]} className="countCompanies">
              <Col xs={12}>
                <div className="count">
                  <div className="count__title">{content[0].title ? content[0].title : 1000}</div>
                  <div className="count__content">{content[0].content ? content[0].content : 'Companies We Helped'}</div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="count">
                  <div className="count__title">{content[1].title ? content[1].title : 30}</div>
                  <div className="count__content">{content[1].content ? content[1].content : 'Trainings Courses'}</div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="count">
                  <div className="count__title">{content[2].title ? content[2].title : 20}</div>
                  <div className="count__content">{content[2].content ? content[2].content : 'Corporate Programs'}</div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="count">
                  <div className="count__title">{content[3].title ? content[3].title : 50}</div>
                  <div className="count__content">{content[3].content ? content[3].content : 'Corporate Programs'}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseUS;
