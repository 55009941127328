import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import webcontentApi from '../../services/webcontent/webcontent.api';
import { IWebcontent, WebcontentType } from '../../@types/webcontent';

export const getWebcontentPage = createAsyncThunk(
  'webcontent/getList',
  async (type: string, { dispatch }) => {
    const { data } = await webcontentApi.get(type);
    return data;
  }
);


type IListPage = {
  home?: IWebcontent[];
  about?: IWebcontent[];
  service?: IWebcontent[];
  carrer?: IWebcontent[];
  contact?: IWebcontent[];
  footer?: IWebcontent[];
  logo?: IWebcontent[];
};

const initialState: IListPage = {
  home: undefined,
  about: undefined,
  service: undefined,
  carrer: undefined,
  contact: undefined,
  footer: undefined,
  logo: undefined,
};

const slice = createSlice({
  name: 'webcontent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWebcontentPage.fulfilled, (state, action) => {
      const { type } = action.payload[0];
      switch (type) {
        case WebcontentType.Home:
          state.home = action.payload;
          break;
        case WebcontentType.About:
          state.about = action.payload;
          break;
        case WebcontentType.Carrers:
          state.carrer = action.payload;
          break;
        case WebcontentType.Contact:
          state.contact = action.payload;
          break;
        case WebcontentType.Footer:
          state.footer = action.payload;
          break;
        case WebcontentType.Logo:
          state.logo = action.payload;
          break;
        case WebcontentType.Service:
          state.service = action.payload;
          break;
        default:
          break;
      }
    });
  },
});
export default slice.reducer;
