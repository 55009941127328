import { Button, Col, Form, Input, Row } from "antd";
import { Typography } from "antd";
import "./FeedbackForm.scss";
import { useTranslation } from "react-i18next";
import { dispatch } from "../../../redux/store";
import { postContact } from "../../../redux/slices/contact";
import { Contact, ContactState } from "../../../@types/contact";
import { useState } from "react";
const { Title } = Typography;
type Props = {};

const Feedback = (props: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onFinish = async () => {
    const formValues = form.getFieldsValue();
console.log(formValues)
    await postContact(formValues);
  };
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  };
  return (
    <div>
    <Title className="title formFeedback__title" level={2}>
        {t("contactPage.feedback.title")}
      </Title>
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      className="formFeedback "
      scrollToFirstError = {true}
      requiredMark ={"optional"}
    >
      
      <Row gutter={16} className="formFeedback--item">
        <Col span={12} className="formFeedback--col">
          <Form.Item
          
            label={t("contactPage.feedback.label.0")}
            name="fullName"
            rules={[
              { 
                required: true, 
                message: t("contactPage.feedback.message.0") 
              },
              {
                pattern: /^[A-Za-z][A-Za-z\s]*$/,
                message: t("contactPage.feedback.message.6") 
              }
            ]}
            validateTrigger={["onChange"]}
            colon={false}
          >
            <Input placeholder={t("contactPage.feedback.placeholder.0")} />
          </Form.Item>
        </Col>
        <Col span={12} className="formFeedback--col">
          <Form.Item
            label={t("contactPage.feedback.label.1")}
            name="phoneNumber"
            validateTrigger={["onChange"]}
            rules={[
              {
                required: true,
                message: t("contactPage.feedback.message.1"),
              },
              {
                pattern: /^0\d{9}$/,
                message: t("contactPage.feedback.message.2"),
              },
            ]}
          >
            <Input
              addonBefore="(+084)"
              placeholder="_ _ _ _ _ _ _ _ _ _"
              maxLength={10}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t("contactPage.feedback.label.2")}
        name="email"
        validateTrigger={["onChange"]}
        rules={[
          {
            required: true,
            message: t("contactPage.feedback.message.3"),
          },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message:t("contactPage.feedback.message.4"),
          },
        ]}
      >
        <Input
          type="email"
          placeholder={t("contactPage.feedback.placeholder.2")}
        />
      </Form.Item>

      <Form.Item
        label={t("contactPage.feedback.label.3")}
        name="note"
        validateTrigger={["onChange"]}
        rules={[{ required: true, message: t("contactPage.feedback.message.5") }]}
      >
        <Input.TextArea
          placeholder={t("contactPage.feedback.placeholder.3")}
          style={{ height: "172px" }}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" onClick={handleClick} loading={loading}>
          {t("contactPage.feedback.buttonsend")}
        </Button>
        
      </Form.Item>
    </Form>
    </div>
  );
};
export default Feedback;
