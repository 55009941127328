import { Button, Carousel } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import React, { useRef } from "react";
import picture from "../../../../assets/images/home-page/gree.png";
import useResponsive from "../../../../hooks/useResponsive";
import "./CarouselPartner.scss";

type Props = {
  partner: string[]
};

const CarouselPartner = ({
  partner
}: Props) => {
  const { isMobile } = useResponsive();
  const carouselRef = useRef<any>(null);
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  return (
    <div className="containerCustom partner"  >
      <Carousel
        dots={false}
        autoplay
        slidesToShow={isMobile ? 2 : 5}
        ref={carouselRef}
        className="carouselPartner"
      >
        {
      partner.map((item, index) => (
        <div className="carouselPartner__cardItem">
          <img src={item} alt="Our Service" />
        </div>
      ))
}
         {/* <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div>
         <div className="carouselPartner__cardItem">
           <img src={picture} alt="Our Service" />
         </div> */}
       {/* } */}
      </Carousel>
      <div className="buttonPartner">
        <Button
          className="buttonPartner__prev"
          type="primary"
          shape="circle"
          onClick={() => {
            handlePrev();
          }}
          icon={<ArrowLeftOutlined />}
        />
        <Button
          className="buttonPartner__next"
          type="primary"
          shape="circle"
          onClick={() => {
            handleNext();
          }}
          icon={<ArrowRightOutlined />}
        />
      </div>
    </div>
  );
};

export default CarouselPartner;
