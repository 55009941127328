import {
  Col,
  Row
} from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Career } from "../../@types/career";
import { ROUTE_PATHS } from "../../constants/url-config";
import { useSelector } from "../../redux/store";
import ButtonCustom from "../button/ButtonCustom";
import "./CareersItem.scss";

type Props = {
  career: Career;
  handleClickApply?: any;
};

const CareersItem = ({ career, handleClickApply }: Props) => {
  const { t } = useTranslation();
  const { provinceList } = useSelector((state) => state.province);
  const provinceName =
    provinceList.find((province) => province.id === career.province_id)?.name ||
    "";
  return (
    <>
      <Row
         data-aos="fade-left"
        gutter={[0, { xs: 12, xl: 0 }]}
        className="careersItem"
        key={career.id}
      >
        <Link
          to={ROUTE_PATHS.CareersDetail.replace(":id", career?.id.toString())}
        >
          <Row>
            <Col xs={24} xl={12}>
              <div className="careersItemLeft">
                <div className="careersItemLeft__name">{career.name}</div>
                <div className="careersItemLeft__position careersItemLeft__position__custom">
                 {career.field}, {t(`${career.jobType}`)}
                </div>
                <div className="careersItemLeft__position">
                  {career.description}
                </div>
              </div>
            </Col>
            <Col xs={24} xl={12}>
              <div className="careersItemRight">
                <div className="careersItemRight__location">{provinceName}</div>
                <div className="careersItemRight__date">
                  {moment(career.createdAt).format("DD-MM-YYYY")}
                </div>
              </div>
            </Col>
          </Row>
        </Link>

        <Col xs={24}>
          <div className="careersItem__button">
            <ButtonCustom
              style={{
                width: 108,
                height: 27,
              }}
              className="mainButton small customButton"
              title={t("applyNow")}
              onClick={() => {
                handleClickApply(career.id);
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CareersItem;
