import { AxiosResponse } from "axios";

import { ObjectTypeParams } from "../../@types/paramsType";
import { getAsync } from "../http-client";

const url = "/objecttype";
const objectTypeApi = {
  get: (params: ObjectTypeParams) => {
    return getAsync(`${url}`, params);
  },
};

export default objectTypeApi;
