export const PAGE_INDEX = 1;
export const NEWS = 31;
export const SERVICE = 32;



export const DEFAULT_PAGINATION = {
  pageSize: 10,
  pageIndex: 1,
  getAll:1000,
};

export const SERVICE_PAGINATION = {
  pageSize: 6,
  pageIndex: 1,
  getAll:1000,
};

export const OBJECT_TYPE_API = {
  brand:'brand'
}

export const dirNameS3 = {
  service:'service',
  news:'news',
  career:'career',
  applyCareer:'apply-career',
} 


export enum ID_EVENT {
  TaxDocument = 192,
  Invoice = 194,
}


export const S3_PROJECT = 'agari'
