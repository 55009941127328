import React from "react";
import "./CardComponent.scss";
import picture1 from "../../assets/images/home-page/card.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../constants/url-config";

type Props = {
  title?: string;
  picture?: any;
  content?: string;
  href?: string;
  id: number | string;
};

const CardComponent = ({ title, picture, content, href, id }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="cardCustom">
      <div className="cardCustom__image">
        <img src={picture || picture1} alt="Card" />
      </div>
      <div className="cardCustom__title">{title}</div>
      <div className="lineCard"></div>
      <div className="cardCustom__content">{content}</div>
      {href && (
        <Link to={href} className="cardCustom__readMore">
          {t("readDetails")}
        </Link>
      )}
    </div>
  );
};

export default CardComponent;
