import { Button, Card, Checkbox, Col, Drawer } from "antd";
import React, { useState } from "react";
import logoRespon from "../../../assets/images/logorespon.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../redux/store";

type Props = {
  open: boolean;
  onClose?: any;
  onSelectRow: (id: string) => void;
  selectedBrand: string[];
  onSelectRowCity: (id: string) => void;
  selectedCity: string[];
};

const DrawerFilter = ({
  open = false,
  onClose,
  onSelectRow,
  selectedBrand,
  onSelectRowCity,
  selectedCity,
}: Props) => {
  const { careerList, careersCount } = useSelector((state) => state.career);
  const { brandList } = useSelector((state) => state.brand);
  const { provinceList } = useSelector((state) => state.province);
  const { t } = useTranslation();

  const handleCheckbox = () => {
    onClose(); // Close the Drawer when a checkbox is changed
  };

  return (
    <>
      <Drawer
        title={<img src={logoRespon} alt="Logo" />}
        placement="left"
        onClose={onClose}
        open={open} // Use the 'open' prop to control Drawer visibility
        className="drawerNav"
        width={"86%"}
      >
        <Col xs={24} xl={4}>
          <div className="careers__filter">
            <Card
              title={t("careersPage.content.1")}
              style={{ borderRadius: 8, marginBottom: 20 }}
            >
              <div className="brandList">
                {brandList?.map((brand) => (
                  <div key={brand.id}>
                    <Checkbox
                      className="custom-checkbox"
                      onClick={() => {
                        onSelectRow(brand.id.toString());
                      }}
                      checked={selectedBrand.includes(brand.id.toString())}
                      onChange={handleCheckbox}
                    >
                      {brand.objectName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Card>
            <Card title={t("city")} style={{ borderRadius: 8 }}>
              <div className="provinceList">
                {provinceList?.map((province) => (
                  <Checkbox
                    key={province.id}
                    className="custom-checkbox"
                    onClick={() => {
                      onSelectRowCity(province.id.toString());
                    }}
                    checked={selectedCity.includes(province.id.toString())}
                    onChange={handleCheckbox}
                  >
                    {province.name}
                  </Checkbox>
                ))}
              </div>
            </Card>
          </div>
        </Col>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
