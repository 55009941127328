import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import type { PaginationProps } from "antd";
import { Button, Card, Checkbox, Col, Empty, Pagination, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import filter from "../../assets/images/carrers/fillter.svg";
import BannerComponent from "../../components/banner-component/BannerComponent";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import CareersItem from "../../components/careers-item/CareersItem";
import useResponsive from "../../hooks/useResponsive";
import { getcareer } from "../../redux/slices/career";
import { dispatch, useSelector } from "../../redux/store";
import "./CareersPage.scss";

import { ParamsType } from "../../@types/paramsType";
import {
  DEFAULT_PAGINATION,
  OBJECT_TYPE_API,
} from "../../constants/app-constants";

import {
  DrawerFilter,
  ModalApplyCareer,
} from "../../components/views/careers-page";
import { getBrandList } from "../../redux/slices/branding";
import { getProvinceList } from "../../redux/slices/province";
import { ICarrerPage } from "../../@types/carrerPage";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";
type Props = {};

const CareersPage = (props: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // useSelector
  const { careerList, careersCount } = useSelector((state) => state.career);
  const { brandList } = useSelector((state) => state.brand);
  const { provinceList } = useSelector((state) => state.province);

  // useState
  const [params, setParams] = useState({
    pageSize: DEFAULT_PAGINATION.pageSize,
    pageIndex: DEFAULT_PAGINATION.pageIndex,
    brand_id: "",
    province_id: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBrand, setselectedBrand] = useState<string[]>([]);
  const [selectedCity, setselectedCity] = useState<string[]>([]);
  const [careerCurrent, setCareerCurrent] = useState();

  const { carrer } = useSelector((state) => state.webcontent);
  let dataCarrer: ICarrerPage | null = null;
  if (carrer && carrer.length > 0) {
    dataCarrer = JSON.parse(carrer[0].content);
  }

  //Handle Show Modal
  const showModal = useCallback((id: any) => {
    setIsModalOpen(true);
    setCareerCurrent(id);
  }, []);
  const handleClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  // Handle Show Drawer On Mobile
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (!carrer) {
      dispatch(getWebcontentPage(WebcontentType.Carrers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const customItemRender: PaginationProps["itemRender"] = (
    current,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <Button
          className="buttonCareers__prev"
          type="primary"
          shape="circle"
          icon={<ArrowLeftOutlined className="careersIcon" />}
        />
      );
    }
    if (type === "next") {
      return (
        <Button
          className="buttonCareers__next"
          type="primary"
          shape="circle"
          icon={<ArrowRightOutlined className="careersIcon" />}
        />
      );
    }
    return originalElement; // For other types, use the default rendering
  };

  const handlePageChange = async (page: number) => {
    setParams({
      ...params,
      pageIndex: page,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // Handle Select Filter
  const onSelectRow = useCallback(
    (id: string) => {
      const selectedBrandIndex = selectedBrand.indexOf(id);

      let newselectedBrand: string[] = [];

      if (selectedBrandIndex === -1) {
        newselectedBrand = newselectedBrand.concat(selectedBrand, id);
      } else if (selectedBrandIndex === 0) {
        newselectedBrand = newselectedBrand.concat(selectedBrand.slice(1));
      } else if (selectedBrandIndex === selectedBrand.length - 1) {
        newselectedBrand = newselectedBrand.concat(selectedBrand.slice(0, -1));
      } else if (selectedBrandIndex > 0) {
        newselectedBrand = newselectedBrand.concat(
          selectedBrand.slice(0, selectedBrandIndex),
          selectedBrand.slice(selectedBrandIndex + 1)
        );
      }
      setselectedBrand(newselectedBrand);
    },
    [selectedBrand]
  );
  const onSelectRowCity = useCallback(
    (id: string) => {
      const selectedCityIndex = selectedCity.indexOf(id);
      let newselectedCity: string[] = [];
      if (selectedCityIndex === -1) {
        newselectedCity = newselectedCity.concat(selectedCity, id);
      } else if (selectedCityIndex === 0) {
        newselectedCity = newselectedCity.concat(selectedCity.slice(1));
      } else if (selectedCityIndex === selectedCity.length - 1) {
        newselectedCity = newselectedCity.concat(selectedCity.slice(0, -1));
      } else if (selectedCityIndex > 0) {
        newselectedCity = newselectedCity.concat(
          selectedCity.slice(0, selectedCityIndex),
          selectedCity.slice(selectedCityIndex + 1)
        );
      }
      setselectedCity(newselectedCity);
    },
    [selectedCity]
  );

  // Lấy danh sách Việc làm
  const getListCareers = async (options: ParamsType) => {
    await dispatch(getcareer(options));
  };

  const formatArrayToString = (array: string[]) => {
    return array.length === 0 ? "" : array.join(",");
  };
  // useEffect
  useEffect(() => {
    getListCareers(params);
  }, [params]);
  useEffect(() => {
    setParams({
      pageSize: DEFAULT_PAGINATION.pageSize,
      pageIndex: DEFAULT_PAGINATION.pageIndex,
      brand_id: formatArrayToString(selectedBrand),
      province_id: formatArrayToString(selectedCity),
    });
  }, [selectedCity, selectedBrand]);

  // Lấy danh sách brand và tỉnh thành phố
  useEffect(() => {
    dispatch(
      getBrandList({
        objectType: OBJECT_TYPE_API.brand,
      })
    );
    dispatch(
      getProvinceList({
        pageSize: DEFAULT_PAGINATION.getAll,
        pageIndex: DEFAULT_PAGINATION.pageIndex,
      })
    );
  }, []);

  return (
    <>
      <BannerComponent
        subtitle={
          dataCarrer?.banner.content ? dataCarrer?.banner.content : t("careers")
        }
        title={
          dataCarrer?.banner.title
            ? dataCarrer?.banner.title
            : "careersPage.banner.title"
        }
        bannerUrl={dataCarrer?.banner.backgroundUrl}
      />
      <BreadcrumbHeader />
      <div className="containerCustom careerPage">
        <div className="careers">
          <Row gutter={[{ xl: 24 }, 0]}>
            <Col xs={0} xl={5}>
              <div className="careers__filter">
                <Card
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  title="Brands"
                  style={{ borderRadius: 8, marginBottom: 20 }}
                >
                  <div className="brandList">
                    {brandList?.map((brand) => (
                      <div key={brand.id}>
                        <Checkbox
                          className="custom-checkbox"
                          onClick={() => {
                            onSelectRow(brand.id.toString()); // Chỉnh sửa onSelectRow để có thêm tham số kiểu danh sách
                          }}
                          checked={selectedBrand.includes(brand.id.toString())} // Sử dụng selectedBrandBrands thay vì selectedBrand
                        >
                          {brand.objectName}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </Card>
                <Card
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  title={t("city")}
                  style={{ borderRadius: 8 }}
                >
                  <div className="provinceList">
                    {provinceList?.map((provinceList) => (
                      <Checkbox
                        key={provinceList.id}
                        className="custom-checkbox"
                        onClick={() => {
                          onSelectRowCity(provinceList.id.toString());
                        }}
                        checked={selectedCity.includes(
                          provinceList.id.toString()
                        )}
                      >
                        {provinceList.name}
                      </Checkbox>
                    ))}
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={24} xl={19}>
              {isMobile ? (
                <Col>
                  <Button className="filter__button" onClick={showDrawer}>
                    <img src={filter} alt="Filter" />
                    {t("careersPage.content.0")}
                  </Button>
                  <DrawerFilter
                    open={open}
                    onClose={onClose}
                    onSelectRowCity={onSelectRowCity}
                    onSelectRow={onSelectRow}
                    selectedBrand={selectedBrand}
                    selectedCity={selectedCity}
                  />
                </Col>
              ) : null}
              {careerList?.length > 0 ? (
                careerList.map((item) => (
                  <CareersItem
                    career={item}
                    key={item.id}
                    handleClickApply={showModal}
                  />
                ))
              ) : (
                <Empty className="empty_career"
                />
              )}
              {careerList.length > 0 && (
                <div className="careers__pagination">
                  <Pagination
                    pageSize={10}
                    defaultCurrent={1}
                    total={careersCount}
                    itemRender={customItemRender}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <ModalApplyCareer
        careerCurrent={careerCurrent}
        isModalOpen={isModalOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default CareersPage;
