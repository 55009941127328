import { AxiosResponse } from "axios"
import { getAsync, postAsync } from "../http-client";

class ContactService {
  post = async (params: any): Promise<AxiosResponse> => {
    return await postAsync('/contact', params)
  }
}

export default new ContactService();
