import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BrandState } from "../../@types/branding";
import { ObjectTypeParams } from "../../@types/paramsType";
import objectTypeApi from "../../services/object-type/object-type.api";

export const getBrandList = createAsyncThunk(
  "brand/getList",
  async (params: ObjectTypeParams, { dispatch }) => {
    const { data } = await objectTypeApi.get(params);
    return data;
  }
);

const initialState: BrandState = {
  brandList: [],
};

const slice = createSlice({
  name: "branding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrandList.fulfilled, (state, action) => {
      state.brandList = action.payload;
    });
  },
});

export default slice.reducer;
